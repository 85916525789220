/* eslint-disable consistent-return */
const phoneInvalidText = 'Введите корректный номер телефона';
const nameInvalidText = 'Введите корректное имя';
const msgInvalidText = 'Выберите тему сообщения';
const mailInvalidText = 'Введите e-mail корректно';
const defaultInvalidText = 'Введите текст сообщения';
const innInvalidText = 'Введите ИНН';
const ogrnInvalidText = 'Введите ОГРН';
const kppInvalidText = 'Введите КПП';
const bicInvalidText = 'Введите БИК';
const billInvalidText = 'Введите номер счёта';
const passwordInvalidText = 'Введите корректный пароль';
const loginInvalidText = 'Введите корректный логин';
const equalsPassText = 'Пароли должны совпадать';
const fileInvalidText = 'Необходимо загрузить файл';
const fieldInvalidText = 'Проверьте правильность ввода';

const minEmailLength = 3;
const maxEmailLength = 40;

const minVal = (name: string, count: number) => `${name} не может быть меньше ${count} симоволов`;
const maxVal = (name: string, count: number) => `${name} не может быть больше ${count} симоволов`;

export const fixPhone = (phone: string) => phone.replaceAll(/["#$%&'()*+,./:<>?\\{}~-]/g, '');

export const useIsValidForm = (errors) =>
  Object.values(errors).every((v) => (v ? !v.length : true));

export const useValidateName = (name) => {
  if (name?.length < 2 || name?.length > 30) {
    return nameInvalidText;
  }
};

export const useValidatePhone = (text) => {
  let phone = JSON.parse(JSON.stringify(text));
  phone = fixPhone(phone);
  if (phone.length !== 11) {
    return phoneInvalidText;
  }
};

export const useValidateMessage = (message) => {
  if (!message) {
    return msgInvalidText;
  }
};

export const useValidateAuthEmail = (mail) => {
  if (mail.length < minEmailLength) {
    return minVal('Email', minEmailLength);
  }
  if (mail.length > maxEmailLength) {
    return maxVal('Email', maxEmailLength);
  }
};

export const usevalidateEmail = (mail, errText = mailInvalidText) => {
  const atposition = mail?.indexOf('@');
  const dotposition = mail?.lastIndexOf('.');
  if (atposition < 1 || dotposition < atposition + 2 || dotposition + 2 >= mail?.length) {
    return errText;
  }
};

export const useValidateByDadata = (data, errText = defaultInvalidText) => {
  if (!data) {
    return errText;
  }
};
export const useValidateText = (text, errText = defaultInvalidText) => {
  if (!text.length) {
    return errText;
  }
};
export const useValidateInn = (inn) => {
  if (inn.length < 10 || inn.length === 11) {
    return innInvalidText;
  }
};
export const useValidateOgrn = (ogrn) => {
  if (ogrn.length < 13) {
    return ogrnInvalidText;
  }
};
export const useValidateKpp = (kpp) => {
  if (kpp.length !== 9) {
    return kppInvalidText;
  }
};
export const useValidateBic = (bic) => {
  if (bic.length !== 9) {
    return bicInvalidText;
  }
};
export const useValidateBill = (bill) => {
  if (bill.length !== 20) {
    return billInvalidText;
  }
};
export const useValidateLogin = (login) => {
  if (login?.length < 2 || login?.length > 30) {
    return loginInvalidText;
  }
};
export const useValidatePassword = (password, propText = passwordInvalidText) => {
  if (password?.length < 6 || password?.length > 30) {
    return propText;
  }
};

export const useEqualsPasswords = (first, second) => {
  if (!first.length || !second.length) {
    return equalsPassText;
  }
  if (first !== second) {
    return equalsPassText;
  }
};
export const useValidateFile = (file) => {
  if (typeof file !== 'object') {
    return fileInvalidText;
  }
};
export const useValidateField = (field) => {
  if (!field?.length || field?.length < 2 || field?.length > 180) {
    return fieldInvalidText;
  }
};

export const useCorrectingForm = <T extends object>(form: T): T => {
  if (form.phone) {
    form.phone = fixPhone(form.phone);
  }
  if (form.workPhone) {
    form.workPhone = fixPhone(form.workPhone);
  }
  if (form.mobilePhone) {
    form.mobilePhone = fixPhone(form.mobilePhone);
  }
  if (form.addPhone) {
    form.addPhone = fixPhone(form.addPhone);
  }
  if (form.workPhone) {
    form.workPhone = fixPhone(form.workPhone);
  }
  if (form.type) {
    form.type = form.type.id;
  }
  return form;
};
